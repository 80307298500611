@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

* {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.scrollable-container {
  max-width: 100vw !important;
}

@media screen and (min-width: 1940px) {
  nb-layout.window-mode.with-scroll {
    padding-top: 0px;
  }

  .scrollable-container {
    height: 100vh !important;
  }
}

nav.fixed {
  max-width: 100vw !important;
}

nb-layout-header.fixed {
  max-width: 100vw !important;
  top: 0px !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #5eb747 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2865b0 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2865b0 !important;
}

button.cl-primary {
  min-width: 121.28px;
  color: #fff !important;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.5px;
  background: linear-gradient(90deg, #2791cf 5.13%, #289c46 106.71%);
  border-radius: 0.25rem;
  text-transform: capitalize;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 5px 0px #34a0926e;
  }
}

.table-container {
  overflow-y: auto;
  border-top: 1px solid #dee2e6;
}

ng2-smart-table .ng2-smart-actions ng2-st-tbody-create-cancel,
ng2-smart-table .ng2-smart-actions ng2-st-actions {
  display: none;
}

.nb-theme-default ng2-smart-table .ng2-smart-actions ng2-st-tbody-edit-delete,
.nb-theme-default ng2-smart-table .ng2-smart-actions ng2-st-tbody-create-cancel,
.nb-theme-default ng2-smart-table .ng2-smart-actions ng2-st-actions {
  display: none;
}

.nb-theme-default ng2-smart-table .ng2-smart-actions a.ng2-smart-action {
  justify-content: end;
}